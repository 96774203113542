import * as React from 'react'
import * as styles from '../components/news.module.scss'
import GlobalWrapper from '../components/GlobalWrapper'
import SEO from '../components/SEO'

const IndexPage = () => {
  return (
    <>
      <SEO
        description="これは公立はこだて未来大学における柳研究室20年間の歩みをまとめたアーカイブサイトのニュース一覧ページです。"
        title="NEWS"
      />
      <main className={styles.container}>
        <GlobalWrapper>
          <div className={styles.wrapper}>
            <h1 className={styles.title}>NEWS</h1>
            <div className={styles.newsItems}>
              <div className={styles.newsItem}>
                <div className={styles.newsDesc}>
                  <p className={styles.newsDate}>2022/10</p>
                  <p className={styles.newsSummary}>
                    公立はこだて未来大学 柳英克研究室 アーカイブサイトを公開
                  </p>
                </div>
              </div>
              <div className={styles.newsItem}>
                <div className={styles.newsDesc}>
                  <p className={styles.newsDate}>2022/03</p>
                  <p className={styles.newsSummary}>
                    柳英克
                    教授が最終講義『デザインの素「見ること」について』を実施
                  </p>
                </div>
              </div>
              <div className={styles.newsItem}>
                <div className={styles.newsDesc}>
                  <p className={styles.newsDate}>2021/04</p>
                  <p className={styles.newsSummary}>
                    柳英克 教授が京都大学大学院特任教授に着任
                  </p>
                </div>
              </div>
              <div className={styles.newsItem}>
                <div className={styles.newsDesc}>
                  <p className={styles.newsDate}>2021/03</p>
                  <p className={styles.newsSummary}>
                    柳英克 教授が公立はこだて未来大学を定年退職
                  </p>
                </div>
              </div>
            </div>
          </div>
        </GlobalWrapper>
      </main>
    </>
  )
}

export default IndexPage
